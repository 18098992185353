.addLink {
    display: flex;
    justify-content: space-between;
    margin-right: 7px;
    margin-left: 7px;
    gap: 9px;
    border-bottom: 1px #323B49 solid;
    padding: 10px
}

.addLinkInput {
    height: 40px;
    border-radius: 10px;
    padding-right: 10px;
    background-color: #313A49;
    border: 0;
    color: #6d6d6d;
    font-size: 16px;
    flex-grow: 2;
}

.button {
    border-radius: 8px;
    background-color: #BE1010;
    border: none;
    color:white;
    padding: 8px 16px;
    flex-grow: 1;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */
    letter-spacing: 0.3px;
}

.disabled {
    background-color: gray;
}


input::placeholder {
    color: #6d6d6d;

    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: 0.2px;
    
  }