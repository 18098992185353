.item {
    border-radius: 8px;
    display: flex;
    background-color: #ffffff;
    align-items: center;
    padding: 10px 20px;
    justify-content: space-between;
}

.link {

    text-decoration: inherit;
    color: inherit;
    cursor: auto;
    width: 90%;
    font-size: 10px;
}

.reports {
    display: flex;
    align-items: center;
    gap: 5px;
}

.reportDown {
    background-color: #FF8989;
}

.icon {
    height: 21px;
    width: 21px;
}

.border {
    border-inline-start: 1px solid #00000080;
    padding-inline-start: 10px;
}

.online {
    display: flex;
    align-items: center;
    height: 21px;
}

.itemProperties {
    display: flex;
    align-items: center;
    gap: 7px;
}