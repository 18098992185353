.sort {
    margin-top: 18px;
    display: flex;
    gap: 4px;
    margin-inline-start: 7px;
}

.button {
    border-radius: 8px;
    background-color: #808080;
    border: none;
    color:white;
    padding: 8px 16px;
}

.selected {
 background-color: #0AA7FF;
}

.reportsTitle {
    color: #FFF;
    text-align: center;
    font-family: Secular One;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 31.2px */
    margin-right: 20px;
    margin-left: 10px;

}

.explain{
    display: flex;
    justify-content: center;
    margin-top: 5px;
    font-size: 14px;
    color: #ffffff;
    opacity: 0.5;
        
}

.explain2 {
    width: 90%;
}