.itemList {
    display: flex;
    gap: 18px;
    flex-direction: column;
    align-items: center;
    margin: 15px 10px;
    overflow: hidden;
    height: 60vh;
    overflow-y: auto;
    padding-bottom: 50px;
}

.load {

    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	outline: inherit;
}

.loadText {
    color: white;
    cursor: pointer;
}

.margin {
    margin-bottom: 50px;
}