/* hebrew */
@font-face {
    font-family: 'Secular One';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/secularone/v12/8QINdiTajsj_87rMuMdKyqDgOOhLLA.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }


.topBar {
    display: flex;
    overflow: hidden;
    color: #ffffff;
    align-items: center;
    border-bottom: 1px solid #323B49;
    padding-right: 0px;
    padding-left: 20px;
    padding-bottom: 10px;
    padding-top: 0px;
    justify-content: space-between;
    margin-top: 10px;
}

.title {
    display: flex;
width: 100px;
height: 92px;
padding: 3px 10px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
color: #FFF;
line-height: 93.5%; /* 22.44px */
text-align: right;
font-family: "Secular One";
font-size: 24px;
font-style: normal;
font-weight: 400;
background: #BE1010;
word-break: break-word;
}


.title2 {
    width: 100px;
    height: 95px;
    flex-shrink: 0;
    color: #FFF;
    text-align: right;
    font-family: "Secular One";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 93.5%; /* 22.44px */
    align-items: center;
    display: flex;
    
}

.titleBar {
gap: 10px;
display: flex;
flex-direction:row;
}
.headline {
    font-size: 24px;
}

.icons {
    display: flex;
    align-items: center;
    gap: 13px
}

.icon {
 height: 24px;
 width: 24px;
}
.iconBig {
    height: 34px;
    width: 34px;
   }
.reportsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
}

.reports {
    font-size: 12px;
}

.reports {
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
}